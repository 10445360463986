import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero />
      <MainFeature
        subheading={<Subheading>Established Since 2019</Subheading>}
        heading={
          <>
            Switakis
            <wbr /> <HighlightedText>apps.</HighlightedText>
          </>
        }
        description={
          <Description>
          Best apps of love, laughs and lifestyle, you'll find in Google Play.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="View Apps"
        primaryButtonUrl="https://play.google.com/store/apps/developer?id=Switakis"
        imageSrc={
          "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&auto=format&fit=crop&w=3450&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>apps.</HighlightedText>
          </>
        }
        tabs={{
          Love: [
            {
              imageSrc: "https://lh3.googleusercontent.com/SoiDH8Wt0sV1if5-CmQRdPzGECZYeqntQ7lKAALAyZLRlmuYd_IevBkVSFkvlChq_w=s360",
              title: "Eu te amo meu amor frases e palavras com imagens",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "5.0",
              reviews: "87",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/OTOuI3dejpOTt7A2f_HJId0ofPsZ8e7xvIrXxpQenmm24WCFiD1nR_QsB2QwxrOOorQ=s360",
              title: "Frases de poemas romanticos e inspiracion",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.6",
              reviews: "124",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/k0Zbam2Q8Gynrqrw1SnJnpJGRwddECqcfkg-X3u3EJYXMEMboAwe9ldLdDsUErxlQ7jZ=s360",
              title: "Mensagens tristes para chorar belos com imagens",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.8",
              reviews: "23",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/zc1ZqzDF5aFYc9x2mL42E7CI1-mDrnkL6xan7hpg81AZnL9fgDN5qOzAXGwnRKkpIMs=s360",
              title: "Frases de carinho para namorada com imagens",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.9",
              reviews: "63",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            }
          ],
          Lifestyle: [
            {
              imageSrc: "https://lh3.googleusercontent.com/QfV71VRvqafYysmM_RklcVxtk-bEfGZAS6TfTLdjfq0aAmA-mKHHnNOtdTs1Eqp_BJjG=s360",
              title: "Chistes buenos con imagenes graciosas",
              content: "PEGI 12 - Lifestyle",
              price: "FREE",
              rating: "4.0",
              reviews: "8",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/hMUcMvezdmKU_m9dMEtXVCITBRu2bBT1cu6dAWp10VgIZAQGnJfC-RGz_nE0Kbfac-M=s360",
              title: "Parabéns amiga mensagens e palavras lindas",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.5",
              reviews: "12",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/T3cb_jBQ9t26nnjiIL8y-zQuzS55RYbKNPVDfI3p6NDQpdVybQDw-Z7Vu_Kffu37oA=s360",
              title: "Cumpleaños feliz frases y palabras para compartir",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.5",
              reviews: "6",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            },
            {
              imageSrc: "https://lh3.googleusercontent.com/ES1ge6lQB4d-5P38q1m049meL6RqLYa2ZZg5E3CtRHBtIrK0lIUHDMWwQ0OF5GZYvA=s360",
              title: "Mensagem de feliz aniversário para meu amiga",
              content: "PEGI 3 - Lifestyle",
              price: "FREE",
              rating: "4.9",
              reviews: "47",
              url: "https://play.google.com/store/apps/developer?id=Switakis"
            }
          ]
        }}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        description="Enjoy the best apps of love, laughs and lifestyle of Google Play and share all the content with your friends."
        statistics={[
          {
            key: "Users",
            value: "100000+",
          },
          {
            key: "Downloads",
            value: "10000+"
          },
          {
            key: "Apps",
            value: "15+"
          }
        ]}
        primaryButtonText="View Apps"
        primaryButtonUrl="https://play.google.com/store/apps/developer?id=Switakis"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2552&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are using <HighlightedTextInverse>Switakis Apps.</HighlightedTextInverse></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
